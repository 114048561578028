import { useRef, useState, useEffect } from 'react';

// Plugins
import { animate } from 'motion';
import { AnimationOptionsWithOverrides } from '@motionone/dom';

// Components
import Backdrop from 'components/UI/Backdrop/Backdrop';

// Hooks & Helpers
import useModalRegister from 'hooks/useModalRegister';

// Types
interface DialogProps {
  show: boolean;
  className?: string;
  theme?: 'light' | 'dark';
  blur?: boolean;

  padding?: 'default' | 'lean';
  position?: 'center' | 'top' | 'bottom';
  size?: 'default' | 'full' | 'banner' | 'custom';
  borderRadius?: 'lg' | 'xl' | '2xl' | '3xl';

  zIndex?: number;

  backdrop?: boolean;
  backdropZIndex?: number;
  backdropOpacity?: number;
  backdropTransparent?: boolean;
  backdropEnableCloseAction?: boolean;

  children: React.ReactNode;

  onClose?: () => void;
}

const animationOptions: AnimationOptionsWithOverrides = { duration: 0.4 };

function Dialog({
  show,
  className = '',
  theme = 'light',
  blur = false,
  padding = 'default',
  position = 'center',
  borderRadius = 'xl',
  size = 'default',
  zIndex,
  backdrop = true,
  backdropZIndex,
  backdropOpacity,
  backdropTransparent = false,
  backdropEnableCloseAction = true,
  children,
  onClose
}: DialogProps) {
  const { modalRegisterAdd, modalRegisterRemove } = useModalRegister();
  const dialogRef = useRef<HTMLElement>(null);

  const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
  const [shouldShow, setShouldShow] = useState<boolean>(false);

  const handleClose = (): void => {
    const dialogElement = dialogRef.current;

    if (dialogElement) {
      setShowBackdrop(false);
      animate(dialogElement, { opacity: 0 }, animationOptions).finished.then(() => {
        onClose && onClose();
        setShouldShow(false);
      });
    }
  };

  useEffect(() => {
    const dialogElement = dialogRef.current;

    if (show) {
      setShouldShow(true);

      if (dialogElement) {
        setShowBackdrop(true);
        animate(dialogElement, { opacity: 1 }, animationOptions);
      }
    }

    if (!show && shouldShow) {
      handleClose();
    }
  }, [show, shouldShow]);

  useEffect(() => {
    if (shouldShow) {
      modalRegisterAdd();
    } else {
      modalRegisterRemove();
    }
    return () => {
      // Remove on unmount
      modalRegisterRemove();
    };
  }, [shouldShow]);

  if (shouldShow) {
    return (
      <>
        {/* Backdrop */}
        {backdrop && (
          <Backdrop
            show={showBackdrop}
            transparent={backdropTransparent}
            zIndex={backdropZIndex}
            opacity={backdropOpacity}
            onClick={backdropEnableCloseAction ? handleClose : undefined}
          />
        )}

        {/* Dialog */}
        <aside
          ref={dialogRef}
          className={`dialog dialog--${theme} ${
            blur ? 'dialog--blur' : ''
          } dialog--${padding} dialog--${position} dialog--${size} dialog-radius--${borderRadius} ${className}`}
          style={{ zIndex }}
        >
          <main className="dialog__content">{children}</main>
        </aside>
      </>
    );
  }

  return null;
}

export default Dialog;
