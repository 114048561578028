import { useReducer, createContext, useContext, useMemo } from 'react';

// Providers
import { useStorefrontContext } from 'providers';

// Helpers
import * as api from 'utils/api';
import throwResponseError from 'utils/throwResponseError';

// Types
import { CustomerAddress, Cart, ServerError, ErrorObject, Notification } from 'types';

interface CheckoutState {
  deliverability: 'deliverable' | 'deliverable_unnecessary_unit' | 'deliverable_incorrect_unit' | 'deliverable_missing_unit' | 'undeliverable' | null;
  americanStates: { id: string; name: string; abbreviation: string }[] | null;

  requesting: { payment: boolean; couponCode: boolean; addressValidation: boolean; states: boolean };
  notification: Notification | null;
}

interface CreatePayment {
  cartId?: string;
  email?: string | null;
  address: CustomerAddress | null;
  shippingRateId?: string;
  source?: string;
}

interface CreateApplyCouponCode {
  cartId: string;
  code: string;
}

interface CheckoutProviderProps {
  checkoutState: CheckoutState;

  createPayment: (payload: CreatePayment) => Promise<any>;
  createApplyCouponCode: (payload: CreateApplyCouponCode) => Promise<any>;
  createRemoveCouponCode: (payload: { cartId: string }) => Promise<any>;
  createAddressValidation: (payload: CustomerAddress) => Promise<any>;

  getAmericanStates: () => Promise<any>;

  setNotification: (payload: { notification: Notification }) => void;
  resetCheckoutState: () => void;
}

// Constants
const SET_REQUESTING = 'SET_REQUESTING';
const SET_ERROR = 'SET_ERROR';

const SET_DELIVERABILITY = 'SET_DELIVERABILITY';
const SET_AMERICAN_STATES = 'SET_AMERICAN_STATES';

const SET_NOTIFICATION = 'SET_NOTIFICATION';

const RESET_STATE = 'RESET_STATE';

const initialState = {
  deliverability: null,
  americanStates: null,

  requesting: { payment: false, couponCode: false, addressValidation: false, states: false },
  notification: null
};

export const CheckoutContext = createContext<CheckoutProviderProps>({
  checkoutState: initialState,

  createPayment: () => Promise.resolve(),
  createApplyCouponCode: () => Promise.resolve(),
  createRemoveCouponCode: () => Promise.resolve(),
  createAddressValidation: () => Promise.resolve(),

  getAmericanStates: () => Promise.resolve(),

  setNotification: () => null,
  resetCheckoutState: () => null
});

const reducer = (state: CheckoutState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUESTING:
      return {
        ...state,
        requesting: { ...state.requesting, ...payload }
      };

    case SET_ERROR:
      return {
        ...state,
        notification: payload.notification
      };

    case SET_DELIVERABILITY:
      return {
        ...state,
        deliverability: payload.deliverability,
        notification: payload.notification
      };

    case SET_AMERICAN_STATES:
      return {
        ...state,
        americanStates: payload.data
      };

    case SET_NOTIFICATION:
      return {
        ...state,
        notification: payload.notification
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

// Used as HOC Wrapper around Routes
export const CheckoutProvider = (props: { children: React.ReactNode }) => {
  const [checkoutState, dispatch] = useReducer(reducer, initialState);
  const { setCart } = useStorefrontContext();

  // Actions
  const createPayment = async (payload: CreatePayment) => {
    const { cartId, email, address, shippingRateId, source } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { payment: true } });

      const bodyPayload = {
        source: source || '',
        ...(email ? { email } : {}),
        ...(address ? { address_attributes: address } : {}),
        ...(shippingRateId ? { shipping_rate_id: shippingRateId } : {})
      };

      const response = await api.post({ resource: `cart/${cartId}/checkout`, bodyPayload });

      return await response
        .json()
        .then((data: any & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { payment: false } });
    }
  };

  const createApplyCouponCode = async (payload: CreateApplyCouponCode) => {
    const { cartId, code } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { couponCode: true } });

      const bodyPayload = { code };
      const response = await api.post({ resource: `cart/${cartId}/apply-code`, bodyPayload });

      return await response
        .json()
        .then((data: Cart & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          // Set Cart in StorefrontContext
          setCart({ cart: data });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { couponCode: false } });
    }
  };

  const createRemoveCouponCode = async (payload: { cartId: string }) => {
    const { cartId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { couponCode: true } });

      const response = await api.post({ resource: `cart/${cartId}/remove-code` });

      return await response
        .json()
        .then((data: Cart & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          // Set Cart in StorefrontContext
          setCart({ cart: data });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { couponCode: false } });
    }
  };

  const createAddressValidation = async (payload: CustomerAddress) => {
    try {
      dispatch({ type: SET_REQUESTING, payload: { addressValidation: true } });

      const bodyPayload = { ...payload };
      const response = await api.post({ resource: 'utilities/address-validate', bodyPayload });

      return await response
        .json()
        .then((data: any & ServerError) => {
          if (data?.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({
            type: SET_DELIVERABILITY,
            payload: {
              deliverability: data?.deliverability || null
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { addressValidation: false } });
    }
  };

  const getAmericanStates = async () => {
    try {
      dispatch({ type: SET_REQUESTING, payload: { states: true } });

      const response = await api.get({ resource: 'states', urlParams: { page: 1, per_page: 100 } });

      return await response
        .json()
        .then((data: any & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({
            type: SET_AMERICAN_STATES,
            payload: { data }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { states: false } });
    }
  };

  const setNotification = (payload: { notification: Notification }) => {
    const { notification } = payload;

    dispatch({ type: SET_NOTIFICATION, payload: { notification } });
  };

  const resetCheckoutState = () => dispatch({ type: RESET_STATE });

  const providerValue = useMemo(
    () => ({
      checkoutState,
      createPayment,
      createApplyCouponCode,
      createRemoveCouponCode,
      createAddressValidation,
      getAmericanStates,
      setNotification,
      resetCheckoutState
    }),
    [checkoutState]
  );

  return <CheckoutContext.Provider value={providerValue}>{props.children}</CheckoutContext.Provider>;
};

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error('useCheckoutContext must be used within a CheckoutProvider ');
  }

  return context;
};
