const alphaCompare = (stringA: string, stringB: string, direction: 'asc' | 'desc') => {
  const stringLowerA = stringA.toLocaleLowerCase();
  const stringLowerB = stringB.toLocaleLowerCase();

  if (stringLowerA < stringLowerB) {
    return direction === 'desc' ? 1 : -1;
  }

  if (stringLowerA > stringLowerB) {
    return direction === 'desc' ? -1 : 1;
  }

  return 0;
};

const numericalCompare = (numberA: number | null = 999999, numberB: number | null = 999999, direction: 'asc' | 'desc') => {
  if (numberA == null) {
    numberA = 999999; // Null should be numberAdded onto the end
  }

  if (numberB == null) {
    numberB = 999999;
  }

  if (numberA < numberB) {
    return direction === 'desc' ? 1 : -1;
  }

  if (numberA > numberB) {
    return direction === 'desc' ? -1 : 1;
  }

  return 0;
};

export { alphaCompare, numericalCompare };
