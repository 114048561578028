import { useReducer, createContext, useContext, useMemo } from 'react';

// Providers
import { useUserContext } from 'providers';

// Helpers
import * as api from 'utils/api';
import throwResponseError from 'utils/throwResponseError';

// Types
import { Customer, ServerError, ErrorObject, Notification } from 'types';

interface AuthState {
  loginPhone: string | null;
  loginPhoneRequestId: string | null;
  loginEmail: string | null;
  loginEmailRequestId: string | null;

  requesting: { phoneCode: boolean; emailCode: boolean; phoneAuth: boolean; emailAuth: boolean };
  notification: Notification | null;
}

interface AuthProviderProps {
  authState: AuthState;

  createLoginPhoneCode: (payload: { phone: string }) => Promise<any>;
  createLoginEmailCode: (payload: { email: string }) => Promise<any>;
  updateLoginPhoneWithValidation: (payload: { requestId: string; code: string }) => Promise<any>;
  updateLoginEmailWithValidation: (payload: { requestId: string; code: string }) => Promise<any>;
  resetAuthState: () => void;
}

// Constants
const SET_REQUESTING = 'SET_REQUESTING';
const SET_ERROR = 'SET_ERROR';

const SET_LOGIN_PHONE = 'SET_LOGIN_PHONE';
const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL';

const RESET_STATE = 'RESET_STATE';

const initialState = {
  loginPhone: null,
  loginPhoneRequestId: null,
  loginEmail: null,
  loginEmailRequestId: null,

  requesting: { phoneCode: false, emailCode: false, phoneAuth: false, emailAuth: false },
  notification: null
};

export const AuthContext = createContext<AuthProviderProps>({
  authState: initialState,
  createLoginPhoneCode: () => Promise.resolve(),
  createLoginEmailCode: () => Promise.resolve(),
  updateLoginPhoneWithValidation: () => Promise.resolve(),
  updateLoginEmailWithValidation: () => Promise.resolve(),
  resetAuthState: () => null
});

const reducer = (state: AuthState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUESTING:
      return {
        ...state,
        requesting: { ...state.requesting, ...payload }
      };

    case SET_ERROR:
      return {
        ...state,
        notification: payload.notification
      };

    case SET_LOGIN_PHONE:
      return {
        ...state,
        loginPhone: payload.phone,
        loginPhoneRequestId: payload.request_id,
        notification: payload.notification
      };

    case SET_LOGIN_EMAIL:
      return {
        ...state,
        loginEmail: payload.email,
        loginEmailRequestId: payload.request_id,
        notification: payload.notification
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

// Used as HOC Wrapper around Routes
export const AuthProvider = (props: { children: React.ReactNode }) => {
  const [authState, dispatch] = useReducer(reducer, initialState);
  const { setUser } = useUserContext();

  // Actions
  const createLoginPhoneCode = async (payload: { phone: string }) => {
    const { phone } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { phoneCode: true } });

      const response = await api.post({ resource: 'c/customers/phone_auth', bodyPayload: { phone } });

      return await response
        .json()
        .then((data: { request_id: string } & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data?.error_localized || data.error, key: data?.error_key || data.error };

            throw errorObject;
          }

          dispatch({ type: SET_LOGIN_PHONE, payload: { ...data, phone, loginPhoneRequestId: data.request_id } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { phoneCode: false } });
    }
  };

  const createLoginEmailCode = async (payload: { email: string }) => {
    const { email } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { emailCode: true } });

      const response = await api.post({ resource: 'c/customers/email_auth', bodyPayload: { email } });

      return await response
        .json()
        .then((data: { request_id: boolean } & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data?.error_localized || data.error, key: data?.error_key || data.error };

            throw errorObject;
          }

          dispatch({ type: SET_LOGIN_EMAIL, payload: { ...data, email, loginPhoneRequestId: data.request_id } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { emailCode: false } });
    }
  };

  const updateLoginPhoneWithValidation = async (payload: { requestId: string; code: string }) => {
    const { requestId, code } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { phoneAuth: true } });

      const response = await api.post({ resource: 'c/customers/phone_auth/verify', bodyPayload: { request_id: requestId, code } });

      return await response
        .json()
        .then((data: { customer: Customer; jwt: string } & ServerError) => {
          const { customer, jwt } = data;

          // Catch error
          if (data.error) {
            const isCodeError = data?.error_key?.includes('incorrect_code');
            const message = isCodeError ? 'Invalid code, please try again' : data?.error_localized || data?.error;
            const key = data?.error_key || data?.error;
            const errorObject = { code: response.status, key, message };

            throw errorObject;
          }

          // Set User in UserContext
          setUser({ user: customer, jwt });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: { code: string; key: string; message: string } | unknown) {
      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { phoneAuth: false } });
    }
  };

  const updateLoginEmailWithValidation = async (payload: { requestId: string; code: string }) => {
    const { requestId, code } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { emailAuth: true } });

      const response = await api.post({ resource: 'c/customers/email_auth/verify', bodyPayload: { request_id: requestId, code } });

      return await response
        .json()
        .then((data: { customer: Customer; jwt: string } & ServerError) => {
          const { customer, jwt } = data;

          // Catch error
          if (data.error) {
            const isCodeError = data?.error_key?.includes('incorrect_code');
            const message = isCodeError ? 'Invalid code, please try again' : data?.error_localized || data?.error;
            const key = data?.error_key || data?.error;
            const errorObject = { code: response.status, key, message };

            throw errorObject;
          }

          // Set User in UserContext
          setUser({ user: customer, jwt });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { emailAuth: false } });
    }
  };

  const resetAuthState = () => dispatch({ type: RESET_STATE });

  const providerValue = useMemo(
    () => ({ authState, createLoginPhoneCode, createLoginEmailCode, updateLoginPhoneWithValidation, updateLoginEmailWithValidation, resetAuthState }),
    [authState]
  );

  return <AuthContext.Provider value={providerValue}>{props.children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider ');
  }

  return context;
};
