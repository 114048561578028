// Plugins
import { v4 as uuid } from 'uuid';
import * as storage from 'utils/storage';

// Types
interface UrlParams {
  [key: string]: string | number | boolean | object | null;
}

interface BodyPayload {
  [key: string]: string | number | boolean | object | null;
}

type BodyPayloadArray = string[] | number[] | boolean[] | object[] | null;

interface Request {
  resource: string;
  urlParams?: UrlParams;
  bodyPayload?: BodyPayload;
  bodyPayloadArray?: BodyPayloadArray;
  studioPreviewToken?: null | string;
  restricted?: boolean; // requires login JWT
}

const COMMIT_REF = import.meta.env.VITE_COMMIT_REF;
const API_URL = import.meta.env.VITE_API_URL;
const JWT_TOKEN = import.meta.env.VITE_JWT_TOKEN!;
const SESSION_HASH = import.meta.env.VITE_SESSION_HASH!;

const getUrl = (resource: string): string => `${API_URL}${resource}`;

const serializeParams = (urlParams: UrlParams): string => {
  if (Object.keys(urlParams).length === 0) return '';

  const paramsArr = [];

  for (const key in urlParams) {
    paramsArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(`${urlParams[key]}`)}`);
  }

  return `?${paramsArr.join('&')}`;
};

const formatJsonParams = (urlParams: UrlParams) => JSON.stringify(urlParams);
const formatArrayParams = (arrayParams: BodyPayloadArray) => JSON.stringify(arrayParams);

const getSessionHash = (): string => {
  const IS_LOCAL = false; // store in sessionStorage

  let hash = storage.get({ key: SESSION_HASH, decrypt: false, isLocal: IS_LOCAL });

  if (!hash) {
    hash = uuid();

    storage.set({ key: SESSION_HASH, value: hash, encrypt: false, isLocal: IS_LOCAL });
  }

  return hash;
};

export const getJwtToken = (): string | null => {
  const jwt = storage.get({ key: JWT_TOKEN, isLocal: true });

  return jwt ? `Bearer ${jwt}` : null;
};

const getAuthHeader = (restricted: boolean) => {
  const jwt = restricted ? getJwtToken() : null;

  const config = jwt ? { Authorization: `${jwt}` } : { 'Session-Hash': getSessionHash() };

  return config;
};

const getHeaders = (restricted: boolean) => {
  const accept = { Accept: 'application/json, text/plain, */*' };
  const photodayClient = { 'Photoday-Client': 'Galleries-Web4' };
  const contentType = { 'Content-Type': 'application/json' };
  const versionHeader = COMMIT_REF ? { 'X-App-Hash': `${COMMIT_REF}` } : {};
  const headers = Object.assign({}, getAuthHeader(restricted), contentType, versionHeader, photodayClient, accept);

  return { headers };
};

export const get = async ({ resource = '', urlParams = {}, restricted = true }: Request): Promise<any> => {
  const composeResource = `${getUrl(resource)}${serializeParams(urlParams)}`;
  const init = { method: 'GET', ...getHeaders(restricted) };

  return await fetch(composeResource, init);
};

export const post = async ({ resource = '', urlParams = {}, bodyPayload = {}, bodyPayloadArray = null, restricted = true }: Request): Promise<any> => {
  const composeResource = `${getUrl(resource)}${serializeParams(urlParams)}`;
  const init = { method: 'POST', body: bodyPayloadArray ? formatArrayParams(bodyPayloadArray) : formatJsonParams(bodyPayload), ...getHeaders(restricted) };

  return await fetch(composeResource, init);
};

export const put = async ({ resource = '', urlParams = {}, bodyPayload = {}, restricted = true }: Request): Promise<any> => {
  const composeResource = `${getUrl(resource)}${serializeParams(urlParams)}`;
  const init = { method: 'PUT', body: formatJsonParams(bodyPayload), ...getHeaders(restricted) };

  return await fetch(composeResource, init);
};

export const del = async ({ resource = '', urlParams = {}, bodyPayload = {}, restricted = true }: Request): Promise<any> => {
  const composeResource = `${getUrl(resource)}${serializeParams(urlParams)}`;
  const init = { method: 'DELETE', body: formatJsonParams(bodyPayload), ...getHeaders(restricted) };

  return await fetch(composeResource, init);
};
