import { useReducer, createContext, useContext, useMemo, useRef, useEffect } from 'react';

// Types
import { Notification } from 'types';

interface GlobalState {
  isModalOpen: boolean;
  isCartOpen: boolean;
  notification: Notification | null;
}

interface GlobalProviderProps {
  globalState: GlobalState;
  addModalId: (id: string) => void;
  removeModalId: (id: string) => void;
  setGlobalNotification: (notification: Notification | null) => void;
  handleOpenCart: () => void;
  handleCloseCart: () => void;
  handleUpdateModalCloseScrollPosition: (scrollY: number) => void;
  resetGlobalState: () => void;
}

// Constants
const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
const SET_CART_OPEN = 'SET_CART_OPEN';
const SET_NOTIFICATION = 'SET_NOTIFICATION';
const RESET_STATE = 'RESET_STATE';

const initialState = {
  isModalOpen: false,
  isCartOpen: false,
  notification: null
} as GlobalState;

export const GlobalContext = createContext<GlobalProviderProps>({
  globalState: initialState,

  handleOpenCart: () => null,
  handleCloseCart: () => null,
  addModalId: () => null,
  removeModalId: () => null,
  setGlobalNotification: () => null,
  handleUpdateModalCloseScrollPosition: () => null,
  resetGlobalState: () => null
});

const reducer = (state: GlobalState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: payload.isModalOpen
      };

    case SET_CART_OPEN:
      return {
        ...state,
        isCartOpen: payload.isCartOpen
      };

    case SET_NOTIFICATION:
      return {
        ...state,
        notification: payload.notification
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const GlobalProvider = (props: { children: React.ReactNode }) => {
  const [globalState, dispatch] = useReducer(reducer, initialState);
  const modalIdsRef = useRef<string[]>([]);
  const windowScrollY = useRef(0);

  const resetGlobalState = () => dispatch({ type: RESET_STATE });

  const addModalId = (id: string) => {
    modalIdsRef.current.push(id);
    dispatch({ type: SET_MODAL_OPEN, payload: { isModalOpen: true } });
  };

  const removeModalId = (id: string) => {
    modalIdsRef.current = modalIdsRef.current.filter((currentId) => currentId !== id);

    if (modalIdsRef.current.length === 0) dispatch({ type: SET_MODAL_OPEN, payload: { isModalOpen: false } });
  };

  const handleOpenCart = () => {
    dispatch({ type: SET_CART_OPEN, payload: { isCartOpen: true } });
  };

  const handleCloseCart = () => {
    dispatch({ type: SET_CART_OPEN, payload: { isCartOpen: false } });
  };

  const handleUpdateModalCloseScrollPosition = (scrollY: number) => {
    windowScrollY.current = scrollY;
  };

  const setGlobalNotification = (notification: Notification | null) => {
    dispatch({ type: SET_NOTIFICATION, payload: { notification: notification || null } });
  };

  useEffect(() => {
    if (globalState.isModalOpen) {
      windowScrollY.current = window.scrollY;
      window.scrollTo(0, windowScrollY.current);
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      document.body.style.top = `-${windowScrollY.current}px`;
    } else {
      document.body.style.position = 'relative';
      document.body.style.overflow = 'auto';
      document.body.style.top = '0';
      window.scrollTo(0, windowScrollY.current);
      windowScrollY.current = 0;
    }
  }, [globalState.isModalOpen]);

  useEffect(() => {
    if (globalState?.notification?.message) {
      setTimeout(() => {
        setGlobalNotification(null);
      }, 10000);
    }
  }, [globalState.notification]);

  const providerValue = useMemo(
    () => ({
      globalState,
      resetGlobalState,
      addModalId,
      removeModalId,
      handleOpenCart,
      handleCloseCart,
      handleUpdateModalCloseScrollPosition,
      setGlobalNotification
    }),
    [globalState]
  );

  return <GlobalContext.Provider value={providerValue}>{props.children}</GlobalContext.Provider>;
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within an GlobalProvider');
  }

  return context;
};
