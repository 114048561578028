export default {
  transparent: 'transparent',
  current: 'currentColor',
  purple: { new: '#2E165D' },
  white: {
    light: '#FFFAFA',
    DEFAULT: '#FFFFFF',
    dark: '#F5F5F5'
  },
  black: {
    light: '#403F50',
    DEFAULT: '#1B1C1E',
    pure: '#000000'
  },
  orange: {
    DEFAULT: '#F76844',
    dark: '#FA6400'
  },
  green: {
    cyan: '#61D9D4',
    ff: '#6DD400',
    DEFAULT: '#48AF94'
  },
  pink: {
    light: '#EAB0C6',
    DEFAULT: '#EA4970',
    bright: '#BF0073',
    dark: '#844F71'
  },
  neutral: {
    50: '#F6F6F7',
    100: '#EDEEEE',
    200: '#E4E5E6',
    300: '#DBDCDD',
    400: '#CACBCC',
    500: '#A6A8AA',
    600: '#959799',
    700: '#858688',
    800: '#747677',
    900: '#646566'
  },
  primaryPurple: {
    50: '#A2A2BD',
    100: '#8F8FAF',
    200: '#7C7CA2',
    300: '#696995',
    400: '#575787',
    500: '#44447A',
    600: '#3D3D6E',
    700: '#363662',
    800: '#303055',
    900: '#292949'
  },
  primaryBlue: {
    link: '#32C5FF',
    50: '#95D3E2',
    100: '#7FCADC',
    200: '#6AC1D6',
    300: '#55B9D0',
    400: '#3FB0CA',
    500: '#2AA7C4',
    600: '#2696B0',
    700: '#22869D',
    800: '#1D7589',
    900: '#196476'
  },
  success: {
    50: '#A2D966',
    100: '#93D24D',
    200: '#93D24D',
    300: '#83CC33',
    400: '#74C51A',
    500: '#64BF00',
    600: '#5AAC00',
    700: '#509900',
    800: '#468600',
    900: '#3C7300'
  },
  warning: {
    40: '#FFE4C0',
    50: '#FAD297',
    100: '#F8C882',
    200: '#F7BF6D',
    300: '#F6B659',
    400: '#F5AD44',
    500: '#F4A42F',
    600: '#DC942A',
    700: '#C38326',
    800: '#AB7321',
    900: '#92621C'
  },
  error: {
    50: '#EE9B97',
    100: '#EA8682',
    200: '#E7726D',
    300: '#E35E58',
    400: '#E04A43',
    500: '#DC362E',
    600: '#C63129',
    700: '#B02B25',
    800: '#9A2620',
    900: '#84201C'
  },
  type: {
    DEFAULT: '#2D2D2D'
  }
};
