import { useReducer, createContext, useContext, useMemo } from 'react';

// Helpers
import * as api from 'utils/api';
import throwResponseError from 'utils/throwResponseError';

// Types
import { Notification, OrganizationGallery, ErrorObject, ServerError, StudioOrganization } from 'types';

interface StudioState {
  organization: StudioOrganization | null;
  organizationGalleries: OrganizationGallery[] | null;

  requesting: { organization: boolean; galleries: boolean };
  notification: Notification | null;
}

interface StudioProviderProps {
  studioState: StudioState;

  getOrganization: (payload: { url: string }) => Promise<any>;
  getOrganizationGalleries: (payload: { organizationId: string; code?: string }) => Promise<any>;

  resetStudioState: () => void;
}

// Constants
const SET_ORGANIZATION = 'SET_ORGANIZATION';
const SET_ORGANIZATION_GALLERIES = 'SET_ORGANIZATION_GALLERIES';
const SET_REQUESTING = 'SET_REQUESTING';
const SET_ERROR = 'SET_ERROR';
const RESET_STATE = 'RESET_STATE';

const initialState = {
  organization: null,
  organizationGalleries: null,

  requesting: { organization: false, galleries: false },
  notification: null
};

export const StudioContext = createContext<StudioProviderProps>({
  studioState: initialState,
  getOrganization: () => Promise.resolve(),
  getOrganizationGalleries: () => Promise.resolve(),
  resetStudioState: () => null
});

const reducer = (state: StudioState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUESTING:
      return {
        ...state,
        requesting: { ...state.requesting, ...payload }
      };

    case SET_ERROR:
      return {
        ...state,
        notification: payload.notification
      };

    case SET_ORGANIZATION:
      return {
        ...state,
        organization: payload.organization
      };

    case SET_ORGANIZATION_GALLERIES:
      return {
        ...state,
        organizationGalleries: payload.organizationGalleries
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const StudioProvider = (props: { children: React.ReactNode }) => {
  const [studioState, dispatch] = useReducer(reducer, initialState);

  // Actions
  const getOrganization = async (payload: { url: string }) => {
    const { url } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { organization: true } });
      const response = await api.post({ resource: 'c/org_pages/search', bodyPayload: { url_path: url }, restricted: false });

      return await response
        .json()
        .then((data: StudioOrganization & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data?.error_localized || data.error, key: data?.error_key || data.error };

            throw errorObject;
          }

          dispatch({ type: SET_ORGANIZATION, payload: { ...data, organization: data } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });
      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { organization: false } });
    }
  };

  const getOrganizationGalleries = async (payload: { organizationId: string; code?: string }) => {
    const { organizationId, code } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { galleries: true } });

      const response = await api.get({
        resource: `c/org_pages/${organizationId}/jobs`,
        urlParams: { ...(code ? { access_code: code } : {}) },
        restricted: false
      });

      return await response
        .json()
        .then((data: OrganizationGallery[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data?.error_localized || data.error, key: data?.error_key || data.error };

            if (data.error.includes('UnauthorizedError') && code) errorObject.message = 'Invalid Access Code';

            throw errorObject;
          }

          dispatch({ type: SET_ORGANIZATION_GALLERIES, payload: { organizationGalleries: data } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      if (error.code !== 401) dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });
      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { galleries: false } });
    }
  };

  const resetStudioState = () => dispatch({ type: RESET_STATE });

  const providerValue = useMemo(() => ({ studioState, getOrganization, getOrganizationGalleries, resetStudioState }), [studioState]);

  return <StudioContext.Provider value={providerValue}>{props.children}</StudioContext.Provider>;
};

export const useStudioContext = () => {
  const context = useContext(StudioContext);

  if (context === undefined) {
    throw new Error('useStudioContext must be used within a StudioProvider ');
  }

  return context;
};
