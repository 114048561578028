interface FilterStaleCache {
  cache: string;
  maxAge?: number; // seconds
}

const maxAgeSeconds = 60 * 15; // 15 minutes

export const filterStaleCache = ({ cache, maxAge = maxAgeSeconds }: FilterStaleCache): { [key: string]: any } | null => {
  try {
    const parsedCache = JSON.parse(cache);
    const parsedCacheTime = parsedCache.time;

    if (parsedCache) {
      if (parsedCacheTime) {
        const elapsedSeconds = (Date.now() - parseInt(parsedCacheTime)) / 1000;

        if (elapsedSeconds > maxAge) return null;

        delete parsedCache.time;
      }

      return parsedCache;
    }
    return null;
  } catch {
    return null;
  }
};

export default filterStaleCache;
