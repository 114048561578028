import { lazy, ComponentType, LazyExoticComponent } from 'react';

// Helpers / Hooks
import * as storage from 'utils/storage';

// Types
type LazyComponentType = LazyExoticComponent<React.ComponentType<any>>;

// Constants
const RELOAD_STORAGE_KEY = 'didRetryReload';

const lazyLoadComponent = function (componentImport: () => Promise<{ default: ComponentType<any> }>, componentName: string): LazyComponentType {
  return lazy(() => {
    return new Promise((resolve, reject) => {
      const key = RELOAD_STORAGE_KEY + componentName;

      // check if the window has already been refreshed
      const storedKey = storage.get({ key });
      const hasRefreshed = storedKey && typeof storedKey === 'string' ? JSON.parse(storedKey) : false;

      // try to import the component
      componentImport()
        .then((component: { default: ComponentType<any> }) => {
          if (hasRefreshed) storage.set({ key, value: 'false' });

          resolve(component);
        })
        .catch((error: any) => {
          if (!hasRefreshed) {
            // not been refreshed yet
            storage.set({ key, value: 'true' });
            return window.location.reload(); // refresh the page
          }
          reject(error);
        });
    });
  });
};

export default lazyLoadComponent;
