import { useRef, useEffect, useState } from 'react';

// Plugins
import { animate } from 'motion';

// Types
interface BackdropProps {
  show: boolean;
  duration?: number;
  transparent?: boolean;
  zIndex?: number;
  opacity?: number;

  onClick?: () => void;
}

function Backdrop({ show, duration = 0.4, transparent, zIndex = 20, opacity = 0.75, onClick }: BackdropProps) {
  const backdropRef = useRef<HTMLDivElement>(null);

  const [shouldShow, setShouldShow] = useState<boolean>(false);

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && onClick) onClick();
  };

  useEffect(() => {
    const backdropElement = backdropRef.current;

    if (show) {
      setShouldShow(true);

      if (backdropElement) {
        animate(backdropElement, { opacity: transparent ? 0 : opacity, pointerEvents: 'auto' }, { duration });
        document.addEventListener('keydown', handleEscape);
      }
    }

    if (!show && shouldShow) {
      if (backdropElement) {
        animate(backdropElement, { opacity: 0, pointerEvents: 'none' }, { duration }).finished.then(() => {
          setShouldShow(false);
        });

        document.removeEventListener('keydown', handleEscape);
      }
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [show, shouldShow]);

  if (shouldShow) {
    return <div ref={backdropRef} className="backdrop" style={{ zIndex }} onClick={onClick}></div>;
  }

  return null;
}

export default Backdrop;
