// Types
import { ErrorObject } from 'types';

export function throwResponseError(response: Response, error?: ErrorObject): void {
  // error is when valid JSON is paresed from response and ErrorObject is thrown
  // response alone is received, when response.json() fails

  const errorObject = error?.code ? error : { code: response.status, message: 'Server error' };

  throw errorObject;
}

export default throwResponseError;
