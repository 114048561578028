const getPagination = (currentPage: number = 1, totalPerPage: number = 100, total: number = 100): number => {
  const totalPages = Math.round(total / totalPerPage);

  if (currentPage <= totalPages) {
    return currentPage + 1;
  }

  return currentPage;
};

export default getPagination;
