// Types
import { Customer, Terms } from 'types';

const getIsOnboardingComplete = (customer: Customer, terms: Terms | null): boolean => {
  const {
    first_name: customerFirstName,
    last_name: customerLastName,
    policies_agreed_at: customerPoliciesAgreedAt,
    phone_verified: isCustomerPhoneVerified,
    email_verified: isCustomerEmailVerified
  } = customer;

  // Check customer details are complete
  if (!customerFirstName?.trim() || !customerLastName?.trim() || !customerPoliciesAgreedAt) return false;
  if (!isCustomerPhoneVerified && !isCustomerEmailVerified) return false;

  // Check policies are current and agreed to
  if (terms && !terms?.customer_changed_at) return false;
  if (terms && terms?.customer_changed_at) {
    const didPoliciesChangeAfterAgreed = new Date(terms.customer_changed_at).getTime() > new Date(customerPoliciesAgreedAt).getTime();

    if (didPoliciesChangeAfterAgreed) return false;
  }

  return true;
};

export default getIsOnboardingComplete;
