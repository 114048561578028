const APP_GALLERY_URL = import.meta.env.VITE_GALLERY_URL;

function ErrorFallback() {
  return (
    <aside className="flex h-screen flex-col items-center justify-center text-center">
      <h1>Oops! Something went wrong</h1>
      <a className="text-body" href={APP_GALLERY_URL}>
        Click here to refresh the screen
      </a>
    </aside>
  );
}

export default ErrorFallback;
