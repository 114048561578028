import { useLocation, Navigate } from 'react-router-dom';

// Plugins
import * as Sentry from '@sentry/react';
import { FullStory, isInitialized as getIsFullstoryInitialized } from '@fullstory/browser';

// Helpers / Hooks
import { getJwtToken } from 'utils/api';
import getIsOnboardingComplete from 'utils/getIsOnboardingComplete';

// Providers
import { useUserContext } from 'providers';
import { useEffect } from 'react';

function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const {
    userState: { user, terms, jwt: userJwt }
  } = useUserContext();

  const jwt = getJwtToken();
  const isOnboardingComplete = !jwt || !user ? false : getIsOnboardingComplete(user, terms);
  const isFullstoryInitialized = getIsFullstoryInitialized();

  useEffect(() => {
    const scope = Sentry.getCurrentScope();

    if (scope) {
      if (isFullstoryInitialized) {
        const fullstorySessionURL = FullStory('getSession', { format: 'url' });

        if (fullstorySessionURL) {
          // Add fullstory session URL to the scope for easier debugging
          scope.setExtra('fullstorySessionURL', fullstorySessionURL);
        }
      }

      if (user) {
        const { id, created_at, updated_at, policies_agreed, policies_agreed_at, tcpa_agreed } = user;

        // User is searchable on sentry.io
        scope.setUser({ id: user.id });
        // Extas are visible within all captured events
        scope.setExtras({ user_id: id, created_at, updated_at, policies_agreed, policies_agreed_at, tcpa_agreed, isloggedIn: userJwt ? 'true' : 'false' });
      } else {
        scope.setUser(null);
      }

      // Tags are searchable on sentry.io
      scope.setTag('isLoggedIn', userJwt ? 'true' : 'false');
    }
  }, [user, userJwt]);

  useEffect(() => {
    if (user?.id && isFullstoryInitialized) {
      // Call FullStory's setIdentity on each page to associate the user with the session.
      // Once set it can't be overwritten, but there is no getter to know if it's set.
      FullStory('setIdentity', { uid: user.id });
    }
  }, [location?.pathname, user?.id]);

  if (!isOnboardingComplete && location) {
    // Redirect them to the onboarding, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ returnPath: location }} replace />;
  }

  return children;
}

export default RequireAuth;
