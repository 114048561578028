import { useReducer, createContext, useContext, useMemo } from 'react';

// Helpers
import * as api from 'utils/api';
import throwResponseError from 'utils/throwResponseError';

// Types
import { ServerError, ErrorObject, Notification } from 'types';

interface EmailState {
  requesting: { unsubscribe: boolean; resubscribe: boolean };
  notification: Notification | null;
}

interface EmailProviderProps {
  emailState: EmailState;

  unsubscribeEmail: (payload: { galleryId: string; email: string }) => Promise<any>;
  resubscribeEmail: (payload: { galleryId: string; email: string }) => Promise<any>;

  resetEmailState: () => void;
}

// Constants
const SET_REQUESTING = 'SET_REQUESTING';
const SET_ERROR = 'SET_ERROR';

const RESET_STATE = 'RESET_STATE';

const initialState = {
  requesting: { unsubscribe: false, resubscribe: false },
  notification: null
};

export const EmailContext = createContext<EmailProviderProps>({
  emailState: initialState,

  unsubscribeEmail: () => Promise.resolve(),
  resubscribeEmail: () => Promise.resolve(),

  resetEmailState: () => null
});

const reducer = (state: EmailState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUESTING:
      return {
        ...state,
        requesting: { ...state.requesting, ...payload }
      };

    case SET_ERROR:
      return {
        ...state,
        notification: payload.notification
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const EmailProvider = (props: { children: React.ReactNode }) => {
  const [emailState, dispatch] = useReducer(reducer, { ...initialState });

  const unsubscribeEmail = async (payload: { galleryId: string; email: string }) => {
    const { galleryId, email } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { unsubscribe: true } });

      const response = await api.post({ resource: `jobs/${galleryId}/email-unsubscribe`, bodyPayload: { email: email }, restricted: false });

      return await response
        .json()
        .then((data: any & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { unsubscribe: false } });
    }
  };

  const resubscribeEmail = async (payload: { galleryId: string; email: string }) => {
    const { galleryId, email } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { resubscribe: true } });

      const response = await api.post({ resource: `jobs/${galleryId}/email-resubscribe`, bodyPayload: { email: email }, restricted: false });

      return await response
        .json()
        .then((data: any & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };
            throw errorObject;
          }

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { resubscribe: false } });
    }
  };

  const resetEmailState = () => dispatch({ type: RESET_STATE });

  const providerValue = useMemo(() => ({ emailState, unsubscribeEmail, resubscribeEmail, resetEmailState }), [emailState]);

  return <EmailContext.Provider value={providerValue}>{props.children}</EmailContext.Provider>;
};

export const useEmailContext = () => {
  const context = useContext(EmailContext);

  if (context === undefined) {
    throw new Error('useEmailContext must be used within an EmailProvider ');
  }

  return context;
};
