import { useEffect, useState } from 'react';

// Components
import Dialog from 'components/UI/Dialog/Dialog';
import { Button, ButtonIcon } from 'components/UI/Button/Button';

// Images
import ReactIconClose from 'assets/images/icons/icon-close.svg?react';

import 'components/OutdatedBrowser/OutdatedBrowser.css';

const OutdatedBrowser = () => {
  const [isOutdated, setIsOutdated] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(true);

  const handleClose = (): void => setShowNotification(!showNotification);

  useEffect(() => {
    const CSS = window.CSS;
    const windowViewport = window.visualViewport;

    const checkIfOutdated = !windowViewport || !CSS || (typeof CSS !== 'function' && typeof CSS !== 'object') || CSS.supports('display', 'grid') === false;

    if (checkIfOutdated) setIsOutdated(true);
  }, []);

  if (isOutdated) {
    return (
      <Dialog size="banner" padding="lean" show={showNotification} backdropEnableCloseAction={false}>
        <aside className="outdated-browser">
          <div className="outdated-browser__container">
            <ButtonIcon className="outdated-browser__close-button" name="close" onClick={handleClose}>
              <ReactIconClose className="w-6 stroke-white" />
            </ButtonIcon>
            <h2 className="text-center text-white">YOUR BROWSER IS CURRENTLY OUT OF DATE</h2>
            <p className="text-center text-body-sm text-white">PLEASE UPDATE YOUR BROWSER TO GET THE FULL WORKING EXPERIENCE</p>
            <a href="https://outdatedbrowser.com/" target="_blank" rel="noopener noreferrer">
              <Button className="outdated-browser__learn-button" size="small" name="learn-more">
                LEARN MORE
              </Button>
            </a>
          </div>
        </aside>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default OutdatedBrowser;
