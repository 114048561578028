// Context provider
import {
  EmailProvider,
  UserProvider,
  AuthProvider,
  GalleriesProvider,
  PhotosProvider,
  AdvancePayProvider,
  StorefrontProvider,
  CheckoutProvider,
  GlobalProvider,
  StudioProvider
} from 'providers';

interface AppContextProviderProps {
  children: React.ReactNode;
}

function AppContextProvider({ children }: AppContextProviderProps) {
  const providers: React.JSXElementConstructor<React.PropsWithChildren<any>>[] = [
    EmailProvider,
    UserProvider,
    AuthProvider,
    GalleriesProvider,
    PhotosProvider,
    AdvancePayProvider,
    StorefrontProvider,
    CheckoutProvider,
    GlobalProvider,
    StudioProvider
  ];

  return (
    <>
      {providers.reduceRight((accumulatedComponent, CurrentComponent) => {
        return <CurrentComponent>{accumulatedComponent}</CurrentComponent>;
      }, children)}
    </>
  );
}

export default AppContextProvider;
