import { useEffect, useRef } from 'react';

// Plugins
import { v4 as uuid } from 'uuid';

// Providers
import { useGlobalContext } from 'providers';

function useModalRegister() {
  const { addModalId, removeModalId } = useGlobalContext();
  const modalIdRef = useRef<string>(uuid());

  const modalRegisterAdd = () => {
    addModalId(modalIdRef.current);
  };

  const modalRegisterRemove = () => {
    removeModalId(modalIdRef.current);
  };

  useEffect(
    () => () => {
      // Safety Check: Remove on unmount
      removeModalId(modalIdRef.current);
    },
    []
  );

  return { modalRegisterAdd, modalRegisterRemove };
}

export default useModalRegister;
