import { useReducer, createContext, useContext, useMemo } from 'react';
//Plugins
import * as Sentry from '@sentry/react';

// Helpers
import * as api from 'utils/api';
import * as sort from 'utils/sort';
import getCodeParam from 'utils/getCodeParam';
import throwResponseError from 'utils/throwResponseError';

// Types
import { Gallery, GalleryPathMode, GalleryLookupField, Subject, ServerError, ErrorObject, Notification, Tag, BackgroundCollection, Background } from 'types';

interface GalleryBackgroundCollection extends BackgroundCollection {
  backgrounds: Background[];
}

type GalleryBackgroundsByCollection = {
  galleryId: string;
  collections: { [key: string]: GalleryBackgroundCollection };
} | null;

interface GallerySMSSubscription {
  galleryId: string;
  phone: string;
  accessId?: string;
}

interface GalleriesState {
  gallery: Gallery | null;
  galleries: Gallery[] | null;

  galleryTags: Tag[] | null;

  galleryBackgrounds: Background[] | null;
  galleryFeaturedBackground: Background | null;
  galleryBackgroundsByCollection: GalleryBackgroundsByCollection;

  showSubscribe: boolean;

  requesting: { galleries: boolean; gallery: boolean; tags: boolean; backgrounds: boolean; subscription: boolean };
  notification: Notification | null;
}

interface GalleriesProviderProps {
  galleriesState: GalleriesState;

  getGalleries: () => Promise<any>;
  getGallery: (payload: { galleryId?: string; galleryPath?: string; code?: string }) => Promise<any>;
  getGalleryModeByGalleryPath: (payload: { galleryPath: string }) => Promise<any>;
  removeGallery: (payload: { galleryId: string; subjectId?: string }) => Promise<any>;

  getGalleryTagsList: (payload: { galleryId: string }) => Promise<any>;
  getGalleryBackgrounds: (payload: { galleryId: string }) => Promise<any>;
  getGalleryLookupFields: (payload: { galleryId: string }) => Promise<any>;

  createGallerySMSSubscription: (payload: GallerySMSSubscription) => Promise<any>;
  getGallerySMSSubscription: (payload: GallerySMSSubscription) => Promise<any>;

  promotionViewRequest: (payload: { galleryId: string; studio_promotion_id: string }) => Promise<any>;

  setFeaturedBackground: (payload: { background: Background }) => void;
  resetGalleryState: () => void;
  resetGalleriesState: () => void;
}

// Constants
const SET_REQUESTING = 'SET_REQUESTING';
const SET_ERROR = 'SET_ERROR';
const SET_GALLERY = 'SET_GALLERY';
const SET_GALLERIES = 'SET_GALLERIES';
const SET_GALLERY_TAGS = 'SET_GALLERY_TAGS';
const SET_GALLERY_BACKGROUNDS = 'SET_GALLERY_BACKGROUNDS';
const SET_GALLERY_FEATURED_BACKGROUND = 'SET_GALLERY_FEATURED_BACKGROUND';
const SET_GALLERY_SMS_SUBSCRIPTION = 'SET_GALLERY_SMS_SUBSCRIPTION';
const RESET_STATE = 'RESET_STATE';
const RESET_GALLERY = 'RESET_GALLERY';
const CATURE_ADD_GALLERY_ERROR = import.meta.env.VITE_CAPTURE_ADD_GALLERY_ERROR === '1';

const initialState = {
  gallery: null,
  galleries: null,

  galleryTags: null,

  galleryBackgrounds: null,
  galleryFeaturedBackground: null,
  galleryBackgroundsByCollection: null,

  showSubscribe: true,

  requesting: { galleries: false, gallery: false, tags: false, backgrounds: false, subscription: false },
  notification: null
};

const GalleriesContext = createContext<GalleriesProviderProps>({
  galleriesState: initialState,

  getGallery: () => Promise.resolve(),
  getGalleryModeByGalleryPath: () => Promise.resolve(),
  removeGallery: () => Promise.resolve(),
  getGalleries: () => Promise.resolve(),
  getGalleryTagsList: () => Promise.resolve(),
  promotionViewRequest: () => Promise.resolve(),
  getGalleryBackgrounds: () => Promise.resolve(),
  getGalleryLookupFields: () => Promise.resolve(),
  getGallerySMSSubscription: () => Promise.resolve(),
  createGallerySMSSubscription: () => Promise.resolve(),

  setFeaturedBackground: () => null,
  resetGalleryState: () => null,
  resetGalleriesState: () => null
});

const reducer = (state: GalleriesState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUESTING:
      return {
        ...state,
        requesting: { ...state.requesting, ...payload }
      };

    case SET_ERROR:
      return {
        ...state,
        notification: payload.notification
      };

    case SET_GALLERY:
      return {
        ...state,
        gallery: payload.gallery
      };

    case SET_GALLERIES:
      return {
        ...state,
        galleries: payload.galleries
      };

    case SET_GALLERY_TAGS:
      return {
        ...state,
        galleryTags: payload.tags
      };

    case SET_GALLERY_BACKGROUNDS:
      return {
        ...state,
        galleryBackgrounds: payload.galleryBackgrounds,
        galleryBackgroundsByCollection: payload.galleryBackgroundsByCollection
      };

    case SET_GALLERY_FEATURED_BACKGROUND:
      return {
        ...state,
        galleryFeaturedBackground: payload.galleryFeaturedBackground
      };

    case SET_GALLERY_SMS_SUBSCRIPTION:
      return {
        ...state,
        showSubscribe: payload.showSubscribe
      };

    case RESET_GALLERY:
      return {
        ...initialState,
        galleries: state.galleries,
        requesting: state.requesting
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

// Used as HOC Wrapper around Routes
export const GalleriesProvider = (props: { children: React.ReactNode }) => {
  const [galleriesState, dispatch] = useReducer(reducer, initialState);

  // Actions
  const getGalleries = async () => {
    try {
      dispatch({ type: SET_REQUESTING, payload: { galleries: true } });

      const response = await api.get({ resource: 'jobs', urlParams: { page: 1, per_page: 1000, order: 'opened_at', dir: 'DESC' } });

      return await response
        .json()
        .then((data: Gallery[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({ type: SET_GALLERIES, payload: { galleries: data } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { galleries: false } });
    }
  };

  const getGallery = async (payload: { galleryId?: string; code?: string; galleryPath?: string }) => {
    const { galleryId, code, galleryPath } = payload;
    const resource = galleryId ? `jobs/${galleryId}` : `jobs/resolve_by`;
    const paramKey = code ? getCodeParam(code) : '';
    const urlParams = {
      ...(galleryPath ? { gallery_path: galleryPath } : {}),
      ...(code ? { [paramKey]: code.toUpperCase().trim() } : {})
    };

    try {
      dispatch({ type: SET_REQUESTING, payload: { gallery: true } });

      const response = await api.get({ resource, urlParams });

      return await response
        .json()
        .then((data: Gallery & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            if (CATURE_ADD_GALLERY_ERROR) {
              // TODO: Remove once determined why deep link users are returned to /galleries
              const sentryError = new Error(`getGallery ${errorObject.message}`);

              Sentry.captureException(sentryError, {
                contexts: { get_gallery_error: { status: response.status, [paramKey]: code } },
                tags: { getGalleryError: 'getGallery' }
              });
            }
            throw errorObject;
          }

          if (data.access_mode === 'access_per_subject' && data.job_status === 'onsale' && paramKey !== 'access_id') {
            const errorObject = { code: response.status, message: 'Please enter a valid access code' };

            throw errorObject;
          }

          const currentGalleries: Gallery[] = galleriesState.galleries || [];
          const currentGalleryIndex = currentGalleries.findIndex((gallery: Gallery) => gallery.id === data.id);

          const newGalleries =
            currentGalleryIndex < 0
              ? [data, ...currentGalleries]
              : [...currentGalleries.slice(0, currentGalleryIndex), data, ...currentGalleries.slice(currentGalleryIndex + 1)];

          dispatch({ type: SET_GALLERIES, payload: { galleries: newGalleries } });
          dispatch({ type: SET_GALLERY_FEATURED_BACKGROUND, payload: { galleryFeaturedBackground: data.featured_background } });
          dispatch({ type: SET_GALLERY, payload: { gallery: data } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: RESET_GALLERY });
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: null, code: error.code } } });
      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { gallery: false } });
    }
  };

  const getGalleryModeByGalleryPath = async (payload: { galleryPath: string }) => {
    const { galleryPath } = payload;
    const resource = `jobs/resolve_by_path?gallery_path=${galleryPath}`;

    try {
      dispatch({ type: SET_REQUESTING, payload: { gallery: true } });

      const response = await api.get({ resource });

      return await response
        .json()
        .then((data: GalleryPathMode & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          // Should be used inline before getGallery(), when accessCode/accessId not provided;
          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: RESET_GALLERY });
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: null, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { gallery: false } });
    }
  };

  const getGalleryLookupFields = async (payload: { galleryId: string }) => {
    const { galleryId } = payload;
    const resource = `c/jobs/${galleryId}/subject_lookup`;

    try {
      dispatch({ type: SET_REQUESTING, payload: { gallery: true } });

      const response = await api.get({ resource });

      return await response
        .json()
        .then((data: GalleryLookupField[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          return data;
        })
        .catch((error: ErrorObject) => {
          throwResponseError(response, error);
        });
    } catch (error: any) {
      dispatch({ type: RESET_GALLERY });
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: null, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { gallery: false } });
    }
  };

  const removeGallery = async (payload: { galleryId: string; subjectId?: string }) => {
    const { galleryId, subjectId } = payload;

    const resource = `jobs/${galleryId}/hide`;
    const bodyPayload = { job_id: galleryId, ...(subjectId ? { subject_id: subjectId } : {}) };

    try {
      dispatch({ type: SET_REQUESTING, payload: { gallery: true } });

      const response = await api.del({ resource, bodyPayload });

      if (response.ok) {
        let newGalleries: Gallery[] = [];

        galleriesState.galleries.forEach((gallery: Gallery) => {
          if (gallery.access_mode === 'access_per_subject' && subjectId) {
            const newSubjects: Subject[] = gallery.subjects.filter((subject: Subject) => subject.id !== subjectId);

            if (newSubjects.length > 0) newGalleries.push({ ...gallery, subjects: newSubjects });
          } else if (gallery.id !== galleryId) {
            newGalleries.push(gallery);
          }
        });

        dispatch({
          type: SET_GALLERIES,
          payload: {
            galleries: newGalleries
          }
        });

        return null;
      }

      return await response
        .json()
        .then((data: any & ServerError) => {
          // Catch error
          if (data.error) {
            if (data.error) {
              const errorObject = { code: data.status, message: data.error_localized || data.error };

              throw errorObject;
            }
          }
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { gallery: false } });
    }
  };

  const getGalleryTagsList = async (payload: { galleryId: string }) => {
    const { galleryId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { tags: true } });

      const response = await api.get({ resource: `jobs/${galleryId}/tags` });

      return await response
        .json()
        .then((data: Tag[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          // Sort tags according to the gallery's tag_sort_type
          const {
            gallery: { tag_sort_type: tagsSortType }
          } = galleriesState;

          let tagsSorted: Tag[];

          switch (tagsSortType) {
            case 'tags_alpha_asc':
              tagsSorted = data.sort((tagA, tagB) => sort.alphaCompare(tagA.name, tagB.name, 'asc'));
              break;
            case 'tags_alpha_desc':
              tagsSorted = data.sort((tagA, tagB) => sort.alphaCompare(tagA.name, tagB.name, 'desc'));
              break;
            case 'tags_photo_count_asc':
              tagsSorted = data.sort((tagA, tagB) => sort.numericalCompare(tagA.photo_count, tagB.photo_count, 'asc'));
              break;
            case 'tags_photo_count_desc':
              tagsSorted = data.sort((tagA, tagB) => sort.numericalCompare(tagA.photo_count, tagB.photo_count, 'desc'));
              break;
            case 'tags_manual':
              tagsSorted = data.sort((tagA, tagB) => sort.numericalCompare(tagA.display_priority, tagB.display_priority, 'asc'));
              break;
            default:
              tagsSorted = data;
          }

          dispatch({ type: SET_GALLERY_TAGS, payload: { tags: tagsSorted } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { tags: false } });
    }
  };

  const getGalleryBackgrounds = async (payload: { galleryId: string }) => {
    const { galleryId } = payload;

    const resource = `jobs/${galleryId}/backgrounds`;
    const urlParams = { per_page: 1000 };

    try {
      dispatch({ type: SET_REQUESTING, payload: { backgrounds: true } });

      const response = await api.get({ resource, urlParams });

      return await response
        .json()
        .then((data: Background[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          const backgroundsByCollection: GalleryBackgroundsByCollection = { galleryId: galleryId, collections: {} };

          data?.forEach((background: Background) => {
            if (
              backgroundsByCollection !== null &&
              (!backgroundsByCollection.collections || !backgroundsByCollection.collections[background.background_collection_id])
            ) {
              const galleryCollections = galleriesState.gallery.background_collections.find(
                (galleryCollections: BackgroundCollection) => galleryCollections.id === background.background_collection_id
              );

              backgroundsByCollection.collections[background.background_collection_id] = {
                ...galleryCollections,
                backgrounds: [background]
              };
            } else if (backgroundsByCollection !== null) {
              backgroundsByCollection.collections[background.background_collection_id].backgrounds.push(background);
            }
          });

          dispatch({ type: SET_GALLERY_BACKGROUNDS, payload: { galleryBackgrounds: data, galleryBackgroundsByCollection: backgroundsByCollection } });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { backgrounds: false } });
    }
  };

  const createGallerySMSSubscription = async (payload: GallerySMSSubscription) => {
    const { galleryId, phone, accessId } = payload;

    const resource = `jobs/${galleryId}/subscribe`;
    const bodyPayload = { phone, ...(accessId ? { access_id: accessId } : {}) };

    try {
      dispatch({ type: SET_REQUESTING, payload: { subscription: true } });

      const response = await api.post({ resource, bodyPayload });

      if (response.ok) {
        dispatch({
          type: SET_GALLERY_SMS_SUBSCRIPTION,
          payload: {
            showSubscribe: false
          }
        });

        return null;
      }

      return await response
        .json()
        .then((data: any & ServerError) => {
          // Catch error
          if (data.error) {
            if (data.error) {
              const errorObject = { code: data.status, message: data.error_localized || data.error };

              throw errorObject;
            }
          }
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { subscription: false } });
    }
  };

  const getGallerySMSSubscription = async (payload: GallerySMSSubscription) => {
    const { galleryId, phone, accessId } = payload;

    const resource = `jobs/${galleryId}/show-subscribe`;
    const urlParams = { phone, ...(accessId ? { access_id: accessId } : {}) };

    try {
      dispatch({ type: SET_REQUESTING, payload: { subscription: true } });

      const response = await api.get({ resource, urlParams });

      return await response
        .json()
        .then((data: { show_subscribe: boolean } & ServerError) => {
          // Catch error
          if (data.error) {
            if (data.error) {
              const errorObject = { code: response.status, message: data.error_localized || data.error };

              throw errorObject;
            }
          }

          dispatch({
            type: SET_GALLERY_SMS_SUBSCRIPTION,
            payload: {
              showSubscribe: data.show_subscribe
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { subscription: false } });
    }
  };

  const promotionViewRequest = async (payload: { galleryId: string; studio_promotion_id: string }) => {
    const resource = `jobs/${payload.galleryId}/promo-views`;
    const bodyPayload = { studio_promotion_id: payload.studio_promotion_id };

    try {
      const response = await api.post({ resource, bodyPayload });

      return await response.json().then((data: any & ServerError) => {
        if (data.error) {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }
        }

        return data;
      });
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    }
  };

  const setFeaturedBackground = (payload: { background: Background }) => {
    const { background } = payload;

    dispatch({ type: SET_GALLERY_FEATURED_BACKGROUND, payload: { galleryFeaturedBackground: background } });
  };

  const resetGalleryState = () => dispatch({ type: RESET_GALLERY });

  const resetGalleriesState = () => dispatch({ type: RESET_STATE });

  const providerValue = useMemo(
    () => ({
      galleriesState,
      getGallery,
      getGalleryModeByGalleryPath,
      removeGallery,
      getGalleries,
      getGalleryBackgrounds,
      getGalleryLookupFields,
      getGallerySMSSubscription,
      createGallerySMSSubscription,
      getGalleryTagsList,
      setFeaturedBackground,
      resetGalleryState,
      resetGalleriesState,
      promotionViewRequest
    }),
    [galleriesState]
  );

  return <GalleriesContext.Provider value={providerValue}>{props.children}</GalleriesContext.Provider>;
};

export const useGalleriesContext = () => {
  const context = useContext(GalleriesContext);

  if (context === undefined) {
    throw new Error('useGalleriesContext must be used within a GalleriesProvider');
  }

  return context;
};
